<script setup lang="ts">
const props = defineProps({
  src: { type: String, required: true },
  width: { type: Number, required: false },
  height: { type: Number, required: false },
  alt: { type: String, required: false },
  big: { type: Boolean, required: false },
  small: { type: Boolean, required: false },
})
</script>

<template v-if="src != ''">
  <img
    v-if="src"
    :data-big="big"
    :data-small="small"
    class="w-auto h-6 data-[big=true]:h-10 data-[small=true]:h-5"
    :src="src"
    :width="width"
    :height="height"
    :alt="alt"
  />
</template>
